@mixin create-your-own-product-badge() {
    background: $c-black;
    border-radius: 0 18px 18px 0;
    position: absolute;
    padding: 5px 15px;
    left: 8px;
    top: 23px;
    z-index: 1;

    @include breakpoint(phablet) {
        top: 14px;
        left: 4px;
        padding: 0 12px 4px;
    }

    span {
        @include font(14px, 20px);

        color: $c-white;

        @include breakpoint(phablet) {
            @include font(12px, 20px);
        }
    }
}
