.colour-selection {
    .item-finder-item & {
        margin-top: 10px;
        color: $c-body;
    }
}

.colour-selection--margin-after {
    margin-bottom: 5px;
}

    .colour-selection__title {
        @include h5;

        margin-bottom: 0px;
    }

    .colour-selection__options {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding: 10px 0 0;
        box-sizing: content-box;
        margin-right: -$site-gutter;
        
        @include breakpoint(tablet) {
            padding: 5px 0px;
            margin-right: -$site-gutter--mobile;
        }

        .colour-selection--wide & {
            margin: -5px;
        }

        @include breakpoint(tablet) {
            .products-list--v2 & {
                margin: -3px -1.5px;
            }
        }
    }

        .colour-selection__option {
            vertical-align: middle;
            display: inline-block;
            margin-right: 6px;
            @include breakpoint(mobile) {
                margin: 0 3px;
            }

            &:last-child {
                @include breakpoint(mobile) {
                    margin-right: $site-gutter--mobile;
                }
            }

            @include breakpoint(tablet) {
                .products-list--v2 & {
                    margin: 3px 1.5px;
                }
            }

            .colour-selection--wide & {
                margin: 5px;
            }
        }

        .colour-selection__extra {
            margin-left: 3px;
            vertical-align: middle;
            display: inline-block;

            @include font(14px, 18px);

            @include breakpoint(tablet) {
                @include font(12px, 18px);

                .products-list--v2 & {
                    margin: 3px 1.5px;
                }
            }
        }
