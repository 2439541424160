:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.product {
  margin-right: 10px;
  margin-bottom: 67px;
  position: relative;
}
.product:last-child {
  margin-right: 0;
}
@media screen and (max-width: 42.5em) {
  .product {
    margin-bottom: 23px;
  }
}
.products-list .product {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .products-list .product {
    flex: 0 0 auto;
    width: calc(50vw - 24px);
    margin-right: 16px;
  }
}
@media screen and (max-width: 34.375em) {
  .products-list .product {
    width: calc(90vw - 16px);
    margin-right: 10px;
  }
}
.products-list .product:last-child {
  position: relative;
  margin-right: 10px;
}
@media screen and (max-width: 42.5em) {
  .products-list .product:last-child {
    margin-right: 16px;
  }
}
.products-list .product:last-child:after {
  content: "";
  display: block;
  position: absolute;
  right: -10px;
  width: 10px;
  height: 1px;
}
@media screen and (max-width: 42.5em) {
  .products-list .product:last-child:after {
    right: -16px;
    width: 16px;
  }
}
.products-list--v2.products-list--v2 .product {
  width: calc(26.6666666667vw - 10px);
  flex: 0 0 auto;
  margin: 0 5px;
}
.products-list--v2.products-list--v2 .product:first-child {
  margin-left: 0;
}
@media screen and (max-width: 42.5em) {
  .products-list--v2.products-list--v2 .product {
    margin: 0 8px;
  }
}
@media screen and (max-width: 42.5em) {
  .products-list--v2.products-list--v2 .product {
    width: calc(50vw - 36px);
  }
}
.slick-slide .products-list--v2.products-list--v2 .product {
  margin: 0;
}
@media screen and (max-width: 42.5em) {
  .slick-slide .products-list--v2.products-list--v2 .product {
    margin: 0;
  }
}
.products-list--v2.products-list--v2 .slick-slide .product {
  padding: 0;
}
@media screen and (max-width: 42.5em) {
  .products-list--v2.products-list--v2 .slick-slide .product {
    padding: 0;
  }
}

.product--search {
  margin-bottom: 0;
}

.product__wishlist-icon {
  position: absolute;
  top: 23px;
  right: 15px;
  width: 20px;
  height: 18px;
  z-index: 1;
  background: transparent;
}
@media screen and (max-width: 42.5em) {
  .products-list--v2 .product__wishlist-icon {
    top: 16px;
    right: 16px;
  }
}
#cp-main .product__wishlist-icon {
  display: none;
}
.product__wishlist-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product__wishlist-icon:hover {
  text-decoration: underline;
}
.product__wishlist-icon:hover .icon-wishlist {
  opacity: 0;
}
.product__wishlist-icon:hover .icon-wishlist-full {
  opacity: 1;
}
.product__wishlist-icon .icon-wishlist-full {
  opacity: 0;
}
.product__wishlist-icon.item-in-wishlist .icon-wishlist {
  opacity: 0;
}
.product__wishlist-icon.item-in-wishlist .icon-wishlist-full {
  opacity: 1;
}

.product__link {
  margin-bottom: 10px;
  position: relative;
  display: block;
  margin-top: -8px;
  margin-right: -8px;
  margin-left: -8px;
  padding: 8px;
  text-decoration: none;
  color: inherit;
}
.product__link:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 42.5em) {
  .product__link {
    margin-top: -4px;
    margin-right: -4px;
    margin-left: -4px;
    padding: 4px;
  }
}
.product__link:focus:not(:hover) {
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 1px #000000;
}
.product__link:hover {
  text-decoration: none;
}
.product--search .product__link {
  display: flex;
}
.products-list--v2.products-list--v2 .product__link {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.product__badge-create-your-own {
  background: #000000;
  border-radius: 0 18px 18px 0;
  position: absolute;
  padding: 5px 15px;
  left: 8px;
  top: 23px;
  z-index: 1;
}
@media screen and (max-width: 42.5em) {
  .product__badge-create-your-own {
    top: 14px;
    left: 4px;
    padding: 0 12px 4px;
  }
}
.product__badge-create-your-own span {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #FFFFFF;
}
@media screen and (max-width: 42.5em) {
  .product__badge-create-your-own span {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.6666666667;
  }
}

.product__images {
  margin-bottom: 15px;
  position: relative;
}
.product__images:last-child {
  margin-bottom: 0;
}
.product--search .product__images {
  margin-right: 24px;
  margin-bottom: 0;
}
.product--search .product__images:last-child {
  margin-right: 0;
}

.product__image {
  overflow: hidden;
}
.product__image:before {
  float: left;
  content: "";
  padding-bottom: 133.3333333333%;
  margin-bottom: 0px;
}
.product__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
.product__image img {
  width: 100%;
  height: auto;
}
.products-list .product__image {
  width: 434px;
}
@media screen and (max-width: 62.4375em) {
  .products-list .product__image {
    width: 280px;
  }
}
@media screen and (max-width: 42.5em) {
  .products-list .product__image {
    width: auto;
  }
}
.products-list--v2 .product__image {
  width: auto;
}
.product--search .product__image {
  overflow: hidden;
  width: 80px;
}
.product--search .product__image:before {
  float: left;
  content: "";
  padding-bottom: 125%;
  margin-bottom: 0px;
}
.product--search .product__image:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}

.product__image--compact {
  overflow: hidden;
}
.product__image--compact:before {
  float: left;
  content: "";
  padding-bottom: 120%;
  margin-bottom: 0px;
}
.product__image--compact:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 42.5em) {
  .product__image--compact {
    overflow: hidden;
  }
  .product__image--compact:before {
    float: left;
    content: "";
    padding-bottom: 133.3333333333%;
    margin-bottom: 0px;
  }
  .product__image--compact:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
  }
}

.product__image--none {
  background-color: #F0EEEB;
  transition: background-color 0.4s ease;
}
.product__image--none:hover {
  background-color: #f9f8f7;
}

.product__image--hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.product__link:hover .product__image--hover {
  opacity: 1;
}

.product__image--square {
  overflow: hidden;
}
.product__image--square:before {
  float: left;
  content: "";
  padding-bottom: 100%;
  margin-bottom: 0px;
}
.product__image--square:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
  visibility: hidden;
}

.product__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 10px;
}
@media screen and (max-width: 42.5em) {
  .product__title {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
.product__link:hover .product__title {
  text-decoration: underline;
}
.product__link--static:hover .product__title {
  text-decoration: none;
}
.product--search .product__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
}
@media screen and (max-width: 42.5em) {
  .product--search .product__title {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.375;
  }
}

.product__price-item {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (max-width: 62.4375em) {
  .product__price-item {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.6666666667;
  }
}
.product__price-item > * {
  margin-right: 10px;
}
.product__price-item > *:last-child {
  margin-right: 0;
}

.product__stock-status,
.product__stock-count {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 62.4375em) {
  .product__stock-status,
  .product__stock-count {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.6666666667;
  }
}
.product__stock-status:last-child,
.product__stock-count:last-child {
  margin-bottom: 0;
}

.product__small-link {
  font-size: 0.875em;
  line-height: 1.5714285714;
  font-weight: 700;
}

.product__price--offer .product__price-value {
  text-decoration: line-through;
}

.item-finder-item .colour-selection {
  margin-top: 10px;
  color: #000000;
}

.colour-selection--margin-after {
  margin-bottom: 5px;
}

.colour-selection__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 0px;
}

.colour-selection__options {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0 0;
  box-sizing: content-box;
  margin-right: -24px;
}
@media screen and (max-width: 62.4375em) {
  .colour-selection__options {
    padding: 5px 0px;
    margin-right: -16px;
  }
}
.colour-selection--wide .colour-selection__options {
  margin: -5px;
}
@media screen and (max-width: 62.4375em) {
  .products-list--v2 .colour-selection__options {
    margin: -3px -1.5px;
  }
}

.colour-selection__option {
  vertical-align: middle;
  display: inline-block;
  margin-right: 6px;
}
@media screen and (max-width: 34.375em) {
  .colour-selection__option {
    margin: 0 3px;
  }
}
@media screen and (max-width: 34.375em) {
  .colour-selection__option:last-child {
    margin-right: 16px;
  }
}
@media screen and (max-width: 62.4375em) {
  .products-list--v2 .colour-selection__option {
    margin: 3px 1.5px;
  }
}
.colour-selection--wide .colour-selection__option {
  margin: 5px;
}

.colour-selection__extra {
  margin-left: 3px;
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2857142857;
}
@media screen and (max-width: 62.4375em) {
  .colour-selection__extra {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .products-list--v2 .colour-selection__extra {
    margin: 3px 1.5px;
  }
}