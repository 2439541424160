@use "sass:math";
@import 'tools/mixins/_focus';
@import 'tools/mixins/_margin';
@import 'tools/mixins/product-card-badges';

.product {
    @include margin(right, 10px);

    margin-bottom: 92px - 25px; // 92 is the desired space, 25 is the grid padding
    position: relative;

    @include breakpoint(phablet) {
        margin-bottom: 48px - 25px; // 48 is the desired space, 25 is the grid padding
    }

    .products-list & {
        margin-bottom: 0;

        @include breakpoint(phablet) {
            flex: 0 0 auto;
            width: calc(50vw - #{$productCarouselWidth});
            margin-right: $site-gutter--mobile;
        }

        @include breakpoint(mobile) {
            width: calc(90vw - #{$site-gutter--mobile});
            margin-right: 10px;
        }

        &:last-child {
            position: relative;
            margin-right: 10px;

            @include breakpoint(phablet) {
                margin-right: $site-gutter--mobile;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: -10px;
                width: 10px;
                height: 1px;

                @include breakpoint(phablet) {
                    right: -$site-gutter--mobile;
                    width: $site-gutter--mobile;
                }
            }
        }
    }

    .products-list--v2.products-list--v2 & {
        width: calc(80vw / 3 - 10px);
        flex: 0 0 auto;
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        @include breakpoint(phablet) {
            margin: 0 8px;
        }

        @include breakpoint(phablet) {
            width: calc(50vw - 36px);
        }

        .slick-slide &  {
            margin: 0;

            @include breakpoint(phablet) {
                margin: 0;
            }
        }
    }

    .products-list--v2.products-list--v2 .slick-slide & {
        padding: 0;

        @include breakpoint(phablet) {
            padding: 0;
        }
    }
}

.product--search {
    margin-bottom: 0;
}

    .product__wishlist-icon {
        position: absolute;
        top: 23px;
        right: 15px;
        width: 20px;
        height: 18px;
        z-index: 1;
        background: transparent;

        .products-list--v2 & {
            @include breakpoint(phablet) {
                top: 16px;
                right: 16px;
            }
        }

        #cp-main & {
            display: none;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            text-decoration: underline;

            .icon-wishlist {
                opacity: 0;
            }

            .icon-wishlist-full {
                opacity: 1;
            }
        }

        .icon-wishlist-full {
            opacity: 0;
        }

        &.item-in-wishlist {
            .icon-wishlist {
                opacity: 0;
            }

            .icon-wishlist-full {
                opacity: 1;
            }
        }
    }

    .product__link {
        @include margin(bottom, 10px);

        position: relative;
        display: block;
        margin-top: -8px;
        margin-right: -8px;
        margin-left: -8px;
        padding: 8px;
        text-decoration: none;
        color: inherit;

        @include breakpoint(phablet) {
            margin-top: -4px;
            margin-right: -4px;
            margin-left: -4px;
            padding: 4px;
        }

        &:focus:not(:hover) {
            @include focus;
        }

        &:hover {
            text-decoration: none;
        }

        .product--search & {
            display: flex;
        }

        .products-list--v2.products-list--v2 & {
            padding: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

        .product__badge-create-your-own {
            @include create-your-own-product-badge();
        }

        .product__images {
            @include margin(bottom, 15px);

            position: relative;

            .product--search & {
                @include margin(right, $site-gutter);

                margin-bottom: 0;
            }
        }

            .product__image {
                @include aspect(math.div(3,4));

                img {
                    width: 100%;
                    height: auto;
                }

                .products-list & {
                    width: 434px;

                    @include breakpoint(tablet) {
                        width: 280px;
                    }

                    @include breakpoint(phablet) {
                        width: auto;
                    }
                }

                .products-list--v2 & {
                    width: auto;
                }

                .product--search & {
                    @include aspect(math.div(4,5));

                    width: 80px;
                }
            }

            .product__image--compact {
                @include aspect(math.div(5,6));

                @include breakpoint(phablet) {
                    @include aspect(math.div(3,4));
                }
            }

            .product__image--none {
                background-color: $c-grey;
                transition: background-color .4s ease;

                &:hover {
                    background-color: lighten($c-grey, 4%);
                }
            }

            .product__image--hover {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: 0;
                transition: opacity .3s ease;

                .product__link:hover & {
                    opacity: 1;
                }
            }

            .product__image--square {
                @include aspect(1);
            }

        .product__title {
            @include font(16px, 22px);

            @include breakpoint(phablet) {
                @include font(14px, 18px);
            }

            margin-bottom: 10px;

            .product__link:hover & {
                text-decoration: underline;
            }

            .product__link--static:hover & {
                text-decoration: none;
            }

            .product--search & {
                @include font(16px, 22px);

                @include breakpoint(phablet) {
                    @include font(16px, 22px);
                }
            }
        }

        .product__price-item {
            @include responsive-body;

            display: flex;
            flex-wrap: wrap;

            // needed to fix weird overflow bug with hide-safely
            position: relative;

            > * {
                @include margin(right, 10px);
            }
        }

        .product__stock-status,
        .product__stock-count {
            @include responsive-body;
            @include margin(bottom, 5px);
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
        }

        .product__small-link {
            @include font(14px, 22px, true);
            @include boldText;
        }

            .product__price-value {

                .product__price--offer & {
                    text-decoration: line-through;
                }
            }
